.pages-listing {
	position: relative;
	height: 100%;
}

.page-image {
	width: 60px;
	height: 56px;
	object-fit: cover;
	margin-right: 12px;
}
