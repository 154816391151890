//
// toolbar
//

// General mode
.toolbar {
	display: flex;
	align-items: center;
	background-color: get($toolbar-config, bg-color);
	padding: get($toolbar-config, padding);
}

// Desktop mode
@include media-breakpoint-up(lg) {
	.toolbar {
		// Fixed toolbar
		.toolbar-fixed & {
			height: var(--kt-toolbar-height);
			position: fixed;
			top: 0;
			right: 0;
			left: 0;
			z-index: get($toolbar-config, z-index);
			box-shadow: get($toolbar-config, box-shadow);
		}

		// toolbar enabled & toolbar not fixed modes
		.toolbar-enabled:not(.toolbar-fixed) & {
			margin-bottom: get($content-spacing, desktop);
		}

		// Fixed header & fixed toolbar modes
		.header-fixed.toolbar-fixed & {
			padding: 0;
			top: get($header-config, fixed, height, desktop);
			border-top: get($toolbar-config, border-top);
		}

		// Aside default enabled, aside fixed & header sticky modes
		.aside-enabled.aside-fixed.toolbar-fixed & {
			left: get($aside-config, width);
			transition: left get($aside-config, transition-speed) ease;
		}

		// Aside default enabled, aside header sticky & aside minimize modes
		.aside-enabled.aside-fixed.toolbar-fixed[data-kt-aside-minimize='on'] & {
			left: get($aside-config, minimized-width);
			transition: left get($aside-config, transition-speed) ease;
		}
	}
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	.toolbar {
		// Fixed toolbar mode
		.toolbar-tablet-and-mobile-fixed & {
			height: var(--kt-toolbar-height-tablet-and-mobile);
			position: fixed;
			top: 0;
			right: 0;
			left: 0;
			z-index: get($toolbar-config, z-index);
			box-shadow: get($toolbar-config, box-shadow);
		}

		// Toolbar enabled & toolbar not fixed modes
		.toolbar-enabled:not(.toolbar-tablet-and-mobile-fixed) & {
			margin-bottom: get($content-spacing, tablet-and-mobile);
		}

		// Fixed header & fixed toolbar modes
		.header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed & {
			padding: 0;
			top: get($header-config, default, height, tablet-and-mobile);
			border-top: get($toolbar-config, border-top);
		}

		// Page title
		.page-title[data-kt-swapper='true'] {
			// display: none !important;
			margin-bottom: 0 !important;
			margin-left: 0.75rem;
		}
	}
}
