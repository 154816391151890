.ContentLoader {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	svg {
		-webkit-transition: all 0.5s ease-in-out;
		transition: all 0.5s ease-in-out;
		-webkit-animation: rotate 1s linear infinite;
		animation: rotate 1s linear infinite;
		font-size: 55px;
		color: #69bc45;
	}
}

@keyframes rotate {
	from {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}

	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
