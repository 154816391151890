.delete-popup {
	&__buttons {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.modal-dialog {
		height: calc(100% - 4rem);
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
