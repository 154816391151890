.delete-btn {
	span {
		margin-right: 0;
	}

	svg {
		width: 24px;
		height: 24px;
	}
}

.heading-image {
	position: absolute;
	top: -11px;
	right: 76px;
	height: 113%;
}
